<template>
    <div>
        <div class="d-flex justify-content-center align-items-center" v-if="showPreloader || $store.state.refresh.fetchingNewData || $store.state.refresh.waiting_filter" style="height: 90vh;">
            <b-spinner variant="primary" label="Text Centered" />
        </div>
        <div v-else>
            <table-Calc-Type :calcTypes="calcTypes" @editForm="editForm" @refresh="refresh" />
			</div>
            <modal-calc-type @refresh="refresh" />
            <modal-calc-type-edit :id="id" @refresh="refresh" />
            <filter-modalVue :fields="fields" @sendToParent="sendToParent"></filter-modalVue>
    </div>
</template>
<script>
import tableCalcType from '@/views/component/Table/tableCalcType.vue'
import ModalCalcType from '@/views/component/Modal/ModalCalcType/ModalCalcType.vue'
import ModalCalcTypeEdit from '@/views/component/Modal/ModalCalcType/ModalCalcTypeEdit.vue'
export default {
    components: {
        tableCalcType,
        ModalCalcType,
        ModalCalcTypeEdit,
    },
    data(){
        return {
            calcTypes: [],
            showPreloader: false,
            id: undefined,
            fields: [ 
                { key: 'checkbox', label: '', thStyle: {width: '10px'}},
                { key: 'id', label: 'Id', sortable: true, thStyle: {width: '10px'}},
                { key: 'name', label: 'Тип расчета', sortable: true, thStyle: {width: '250px'}}
            ],
        }
    },
    mounted(){
        this.$store.commit('pageData/setdataCount', null)
        this.openFilter()
    },
    methods: {
        editForm(id){
            this.id = id
            setTimeout(this.openModal, 0);
        },
        openModal(){
            this.$bvModal.show('calcTypeEdit')
        },
        refresh(){
            this.showPreloader = true
            this.$http
                .get('tariff/calc-types')
                .then(res => {
                    this.calcTypes = res.data
                    this.$store.commit('pageData/setdataCount', this.calcTypes.length)
                    this.showPreloader = false
                    this.$store.commit('REFRESH_DATA', false)
                }).catch(err => {
                    
                })
        },
        sendToParent(tableData){
            this.calcTypes = tableData
            this.$store.commit('pageData/setdataCount', this.calcTypes.length)
        },
        openFilter(){
            let allRoutes = this.$store.state.draggableTab.tabs
            let param = JSON.parse(localStorage.getItem(this.$route.name + 'data'))
            let arrayRoutes = []
            allRoutes.forEach(element => {
                arrayRoutes.push(element.path)
            });
            if (arrayRoutes.includes(this.$route.path) == true) {
                this.showPreloader = true
                this.$http
                    .get(`${this.$route.name}`, {params: param}).then(res => {
                        this.calcTypes = res.data
                        this.$store.commit('pageData/setdataCount', this.calcTypes.length)
                        this.showPreloader = false
                        this.$store.commit('REFRESH_DATA', false)
                    })
            }else if(arrayRoutes.includes(this.$route.path) == false){
                this.$bvModal.show(this.$route.name + 'filter')
            }
        },
    },
    computed: {
        fetchingNewData(){
            return this.$store.state.refresh.fetchingNewData
        }
    },
    watch: {
        fetchingNewData(val){
            if (val){
                this.$http
                .get(`${this.$route.name}`)
                .then(res =>{
                    this.calcTypes = res.data
                    this.$store.commit('pageData/setdataCount', this.calcTypes.length)
                    this.$store.commit('REFRESH_DATA', false)
                })
                }
            }
    }
}
</script>