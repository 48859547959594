<template>
    <div>
        <b-modal id="calcTypeEdit" title="Изменить данные тип расчета" size="xs" ok-only ok-title="Изменить" hide-footer no-enforce-focus :no-close-on-backdrop="true" @show="opened">
            <b-row>
                <b-col cols="12">
                    <b-input v-model="calcType.name" placeholder="Наименования" />
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" class="d-flex justify-content-between">
                    <b-button
                    :disabled="submitButtonDisabled"
                    class="mt-2 col-md-5"
                    variant="primary"
                    @click="editCalcType">
                        Изменить
                    </b-button>
                    <b-button class="mt-2 col-md-5" variant="secondary" @click="closeModal">
                        Отмена
                    </b-button>
                </b-col>
            </b-row>
        </b-modal>
    </div>
</template>
<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
export default {
    props: ['id'],
    components: {
        ToastificationContent,
    },
    data(){
        return {
            calcType: {},
            submitButtonDisabled: false,
        }
    },
    methods:{
        editCalcType(){
            this.submitButtonDisabled = true
            this.$http
                .patch(`tariff/calc-types/${this.id.id}`, this.calcType)
                .then(res=> {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: 'Успех!',
                            icon: 'CheckIcon',
                            variant: 'success',
                            text: res.data.message,
                        },
                    })
                    this.closeModal()
                    // this.$bvModal.hide('calcTypeEdit')
                    this.$emit('refresh')
                })
                .catch(err=> {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                        title: 'Некорректные данные!',
                        icon: 'XIcon',
                        variant: 'danger',
                        text: err.response.data.errors,
                        },
                    })
                })
                .finally(()=>{
                    this.submitButtonDisabled = false
                })
        },
        opened(){
            this.$http
                .get(`tariff/calc-types/${this.id.id}/edit`)
                .then(res => {
                    this.calcType = res.data
            })
            // this.clearData()
        },
        closeModal(){
            this.$bvModal.hide('calcTypeEdit')
            // this.clearData()
        },
        // clearData(){
        //     this.calcTypeEdit = {}
        // }
    }
}
</script>